<template>
  <div class="p-2">
    <div class="card">
      <form class="app-search">
        <div class="form-group w-50 mb-2">
          <label for="per-page">Nombre par page:</label>
          <input
            type="number"
            id="per-page"
            class="form-control"
            step="5"
            min="10"
            max="100"
            v-model="perPage"
          />
        </div>
        <div class="app-search-box">
          <div class="input-group">
            <input
              type="search"
              v-model="search"
              class="form-control"
              placeholder="Rechercher par,groups, nom, prenom, email, id, ville, tel..."
            />
          </div>
        </div>
      </form>
    </div>

    <div class="card">
      <table id="basic-datatable" class="table dt-responsive nowrap w-100">
        <thead>
          <tr>
            <th>#</th>
            <th>Utilisateur</th>
            <th>Tel</th>
            <th>Location</th>
            <th>Group</th>
            <th>Verifie</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in paginate" :key="t.id">
            <td>{{ t.id }}</td>
            <td>
              <router-link :to="{ name: 'ShowUser', params: { id: t.id } }">
                {{ t.firstName }} {{ t.lastName }}
              </router-link>
              [<a :href="'mailto:' + t.email">{{ t.email }}</a
              >]
            </td>
            <td>{{ t.phone }}</td>
            <td>{{ printLocation(t) }}</td>
            <td>{{ t.groups }}</td>
            <td>
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="t.isVerified"
                  @change="toggle(t, 'isVerified')"
                />
              </div>
            </td>
            <td>
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="t.isActive"
                  @change="toggle(t, 'isActive')"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="perPage < searchResult.length"
        class="d-flex justify-content-around border mt-1"
      >
        <button
          :disabled="page === 1"
          class="btn btn-sm btn-info"
          @click="prev"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <p>page {{ page }}/{{ totalPages }}</p>
        <button
          :disabled="page == totalPages"
          class="btn btn-sm btn-info"
          @click="next"
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      users: [],
      perPage: 10,
      page: 1,
      search: "",
    };
  },
  methods: {
    async loadusers() {
      this.$store
        .dispatch("loadStudents", "all")
        .then((users) => (this.users = users));
    },
    printLocation({ country, city }) {
      if (country === "" || country == null) return city;
      if (city === "" || city == null) return country;
      return city + ", " + country;
    },
    async toggle(e, field) {
      const data = { ...e, field: !e[field] };
      this.$http.put(`/auth/admin/user/${e.id}`, data).then(() => {
        this.loadusers();
      });
    },
    prev() {
      if (this.page > 1) this.page--;
    },
    next() {
      if (this.page < this.totalPages) this.page++;
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.searchResult.length / this.perPage);
    },
    paginate() {
      let start = (this.page - 1) * this.perPage;
      if (start < 0) start = 1;
      return this.searchResult.slice(start, start + this.perPage);
    },
    searchResult() {
      if (!this.search) return this.users;
      return this.users.filter(
        (s) =>
          s.id == this.search ||
          s.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
          s.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
          s.groups.toLowerCase().includes(this.search.toLowerCase()) ||
          s.phone.toLowerCase().includes(this.search.toLowerCase()) ||
          s.city.toLowerCase().includes(this.search.toLowerCase()) ||
          s.country.toLowerCase().includes(this.search.toLowerCase()) ||
          s.email.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadusers();
    });
  },
};
</script>
<style lang=""></style>
